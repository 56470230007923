@font-face {
  font-family: "SohoStd-ExtraLight";
  src: url("ef438c32ea3974219a3346f9aba50cda.eot");
  src: url("ef438c32ea3974219a3346f9aba50cda.eot?#iefix")
      format("embedded-opentype"),
    url("ef438c32ea3974219a3346f9aba50cda.woff") format("woff"),
    url("ef438c32ea3974219a3346f9aba50cda.woff2") format("woff2"),
    url("ef438c32ea3974219a3346f9aba50cda.ttf") format("truetype");
  /* url("ef438c32ea3974219a3346f9aba50cda.svg#SohoStd-ExtraLight") format("svg"); */
  font-weight: normal;
  font-style: normal;
}
